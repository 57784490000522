<script setup>
import TextWithLineAfter from "@/components/TextWithLineAfter.vue";
import { useWebStore } from '@/store/web.js'

const store = useWebStore();

const features = [
  { title: 'pmp2', description: 'pmp3', image: '/gep_belso.webp' },
  { title: 'pmp4', description: 'pmp5', image: '/gep2.webp' },
  { title: 'pmp6', description: 'pmp7', image: '/Erős váz és zárszerkezet.webp' },
  { title: 'pmp8', description: 'pmp9', image: '/sarga-zold-mosott-hatterrel.webp' },
];
</script>

<template>
  <div class="p-2 sm:w-3/4 sm:mx-auto xl:w-3/5 2xl:w-1/2">
    <text-with-line-after :text="store.getLocaleString('hb1')"/>
    <h2 class="InterExtraBold text-3xl uppercase mt-12">{{ store.getLocaleString('pmp1') }}</h2>

    <div class="grid grid-cols-2 mt-11 gap-y-5" style="grid-template-rows: repeat(4,300px);">
      <template v-for="(feature, index) in features" :key="index">
        <template v-if="index % 2 === 0">
          <div 
            class="bg-cover bg-center h-full w-5/6"
            :style="{ backgroundImage: `url('${feature.image}')` }"
          ></div>
          <span class="h-min my-auto">
            <h5 class="text-xl InterExtraBold uppercase text-semibold text-left lg:text-2xl">
              {{ store.getLocaleString(feature.title) }}
            </h5>
            <h6 class="text-lg InterMedium text-left mt-7 lg:text-xl">
              {{ store.getLocaleString(feature.description) }}
            </h6>
          </span>
        </template>
        <template v-else>
          <span class="h-min my-auto">
            <h5 class="text-xl InterExtraBold uppercase text-semibold text-right lg:text-2xl">
              {{ store.getLocaleString(feature.title) }}
            </h5>
            <h6 class="text-lg InterMedium text-right mt-7">
              {{ store.getLocaleString(feature.description) }}
            </h6>
          </span>
          <div 
            class="bg-cover bg-center h-full w-5/6 justify-self-end"
            :style="{ backgroundImage: `url('${feature.image}')` }"
          ></div>
        </template>
      </template>
    </div>
  </div>
</template>
