<template>
    <span>{{ displayedText }}</span>
  </template>
  
  <script setup>
  import { ref, watch } from 'vue'
  
  const props = defineProps({
    text: {
      type: String,
      required: true
    },
    speed: {
      type: Number,
      default: 50
    }
  })
  
  const displayedText = ref('')
  
  watch(() => props.text, (newText) => {
    displayedText.value = ''
    typeText(newText)
  }, { immediate: true })
  
  function typeText(text) {
    let index = 0
    const interval = setInterval(() => {
      if (index < text.length) {
        displayedText.value += text[index]
        index++
      } else {
        clearInterval(interval)
      }
    }, props.speed)
  }
  </script>
  