<template>
  <div>
    <div id="counter" class="counter text-center text-5xl lg:text-6xl" :style="cssProps"></div>
    <h3 class="text-md text-center lg:text-lg">{{ text }}</h3>
  </div>
</template>
<script>
export default {
  name: 'counterComp',
  props:['text','maxValue','size','color'],

  computed: {
    cssProps() {
      return {
        '--maxValueProp': (this.maxValue),
        '--size': (this.size),
        '--colorOfCounter':this.color
      }
    }
  },
}
</script>

<style scoped lang="scss">
#counter {
  color:var(--colorOfCounter);
  --newNum: var(--maxValueProp);
  animation: counter 2s forwards ease-in-out;
  counter-reset: num var(--num);
}
#counter::after {
  content: counter(num);
}

.counter{
}
h3{
  margin: unset;
  color: black;
}

@property --num {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}
@keyframes counter {
  0% {
    --num: 0;
  }

  100% {
    --num: var(--newNum);
  }
}
</style>