<script setup>
  import { useWebStore } from '@/store/web.js'
  const store = useWebStore();
  const articles = reactive([
        {
          title:store.getLocaleString('wfd2'),
          text:store.getLocaleString('wfd3'),
          link:'szerelekgyartas1'
        },
        {
          title:store.getLocaleString('wfd4'),
          text:store.getLocaleString('wfd5'),
          link:'szerelekgyartas'
        },
        {
          title:store.getLocaleString('wfd6'),
          text:store.getLocaleString('wfd7'),
          link:'szerelekgyartas2'},
      ]
      )
</script>

<template>
  <div class="bg-slate-950 text-white p-4">
    <h3 class="text-3xl InterExtraBold uppercase mb-8 lg:text-4xl lg:mb-16 ">{{store.getLocaleString('h10')}}</h3>
    <div class="sm:w-3/4 sm:mx-auto lg:w-11/12 lg:grid lg:grid-cols-2 xl:w-3/4 2xl:w-3/5">
      <div class="">
        <text-with-line-after class="hidden md:block" :text="store.getLocaleString('hb5')"/>
        <h3 class="hidden text-4xl InterExtraBold uppercase lg:block lg:text-left">{{store.getLocaleString('wfd1')}}</h3>
        <read-more-button
            :class="[['desktop','laptop','tabletL'].includes(store.screenSize)?'':'justify-center']"
            goto="bucket"
            :text="store.getLocaleString('rmb1')"
            identification="leftSideReadMore"
            :bordered="true"
            link:
            style="margin-top: 6%"
        />
        <ImageLazy class="-mt-9 sm:w-3/4 sm:mx-auto lg:w-full" src="/kanal_wireframe.webp"/>
      </div>
      <div class="">
        <div v-for="article in articles" v-bind:key="article.link" class="border-t-[1px] pt-2 lg:text-left">
          <h5 class="text-2xl InterExtraBold  mb-8">{{article.title}}</h5>
          <p class=" sm:w-3/4 sm:m-auto text-md lg:m-2 lg:text-lg">{{article.text}}</p>
          <read-more-button
              class="justify-end mt-3 mb-7 lg:text-xl"
              goto="bucket"
              :text="store.getLocaleString('wfd8')"
              :identification="article.link"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReadMoreButton from "@/components/ReadMoreButton.vue";
import TextWithLineAfter from "@/components/TextWithLineAfter.vue";

export default {
  name:'WireFrameDesign',
  components: {TextWithLineAfter, ReadMoreButton},
  data(){
    return{

    }
  },
}
</script>
