<script setup>
  import { ref } from 'vue'
  import { useWebStore } from '@/store/web.js'
  import { useIntersectionObserver } from '~/composables/useIntersectionObserver'
  import TypeWriter from '~/components/TypeWriter.vue'
  import ReadMoreButton from "@/components/ReadMoreButton.vue"
  import TextWithLineAfter from "@/components/TextWithLineAfter.vue"

  const store = useWebStore()
  const { isIntersecting, elementRef } = useIntersectionObserver({ threshold: 0.5 })

  const articles = reactive([
      {
        title:store.getLocaleString('bpm3'),
        text:store.getLocaleString('bpm4'),
        linkText:store.getLocaleString('bpm5'),
        id:'flakongyartas1',
        goto:'flakongyartas'
      },
      {
        title:store.getLocaleString('bpm6'),
        text:store.getLocaleString('bpm7'),
        linkText:store.getLocaleString('bpm8'),
        id:'flakongyartas2',
        goto:'webshop'
      },
      {
        title:store.getLocaleString('bpm9'),
        text:store.getLocaleString('bpm10'),
        linkText:store.getLocaleString('bpm11'),
        id:'flakongyartas3',
        goto:'flakongyartas'
      },
    ])
</script>

<template>
  <div class="p-4 sm:w-3/4 sm:mx-auto lg:w-11/12 xl:w-3/4 2xl:w-3/5">
    <div class="lg:grid lg:grid-cols-2 lg:gap-8">
      <div class="relative lg:my-auto">
        <ImageLazy class="sm:w-3/4 sm:mx-auto lg:w-full" src="/kek_flakon_frocskolo_viz.webp" :alt="store.getLocaleString('imgAlt3')"/>
        <h2 ref="elementRef" class="text-3xl InterExtraBold uppercase mb-8 lg:text-4xl lg:absolute lg:top-1/4 lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-full lg:w-full lg:px-4 lg:mix-blend-difference lg:text-cyan-600 lg:w-5/6">
          <ClientOnly>
            <Transition name="fade">
              <TypeWriter 
                v-if="isIntersecting" 
                :text="store.getLocaleString('bpm1')" 
                :speed="65"
              />
            </Transition>
          </ClientOnly>
        </h2>
      </div>
      <div>
        <div v-for="article in articles" :key="article.id" class="mb-8 lg:border-t border-black lg:pt-1">
          <h5 class="text-2xl InterExtraBold text-orange-500 mb-5 lg:text-left lg:text-3xl">{{ article.title }}</h5>
          <p class="sm:w-3/4 sm:mx-auto lg:text-left lg:text-lg lg:m-2">{{ article.text }}</p>
          <read-more-button
            class="justify-end mt-3 mb-7 lg:text-xl"
            :text="article.linkText"
            :goto="article.goto"
            :identification="article.id"
            color="black"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
