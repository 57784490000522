<script setup>
import { useWebStore } from '@/store/web.js'
import ImageLazy from './ImageLazy.vue' // Assuming you have this component

const store = useWebStore();
</script>

<template>
  <div :class="[
    'grid gap-5 mt-5',
    store.screenSize === 'mobile' || store.screenSize === 'tablet'
      ? 'grid-cols-1'
      : 'grid-cols-4 grid-rows-2'
  ]">
    <ImageLazy 
      v-for="(photo, index) in photos" 
      :key="photo.url" 
      :src="`/${photo.src}`"
      :class="[
        'max-w-full max-h-full',
        index === 0 && !(store.screenSize === 'mobile' || store.screenSize === 'tablet') ? 'col-span-2' : '',
        index === 1 && !(store.screenSize === 'mobile' || store.screenSize === 'tablet') ? 'col-start-3 col-end-5 row-span-2' : ''
      ]"
    />
  </div>
</template>

<script>
export default {
  name: "PhotoMontage",
  props: ['photos']
}
</script>
